import React from "react"
import { Link, graphql } from "gatsby"
import Spacer from "../components/spacer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import TagStrip from "../components/tag-strip"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteAuthor = data.site.siteMetadata.author
  const posts = data.allMarkdownRemark.edges

  const dashify = (string) => {
    return string.replace(/\s+/g, '-').toLowerCase();
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle}/>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        const author = node.frontmatter.author || siteAuthor.name || node.fields.slug
        const image = require(`../../content/authors/${dashify(author)}/image.png`)
        const tags = node.frontmatter.tags
        
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: -4,
                }}
              >
                <Link
                  style={{ boxShadow: `none`, color: "#FF6708" }}
                  to={node.fields.slug}
                >
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <Spacer />
              <small><img style={{marginTop: "auto", marginBottom: "auto", transform: "translateY(-1.5px)", objectFit: "cover", borderRadius: "100%", verticalAlign: "middle", width: 20, height: 20 }} src={image}/> <Spacer x={3} inline /><b>{author}</b></small>
              <Spacer y={5}/>
              <TagStrip tags={tags} style={{marginTop: 10}}/>
            </header>
            <Spacer y={15}/>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            tags
          }
        }
      }
    }
  }
`
